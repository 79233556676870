import { type PropsWithChildren } from "react";

import SuspenseLoader from "@ll-web/components/SuspenseLoader";
import { useGetProposalById } from "@ll-web/features/proposals/async/useProposalsQueries";
import { useActiveProposalId } from "@ll-web/features/proposals/hooks/useActiveProposal";
import Status404 from "@ll-web/features/statusPages/pages/Status404";

const ActiveProposalContainer = ({ children }: PropsWithChildren) => {
  const proposalId = useActiveProposalId();
  const { data: activeProposal, isPending } = useGetProposalById({
    id: proposalId,
  });

  if (isPending) {
    return <SuspenseLoader />;
  }

  if (!activeProposal) {
    return <Status404 />;
  }

  return children;
};

export default ActiveProposalContainer;
