import "keen-slider/keen-slider.min.css";
import "react-toastify/dist/ReactToastify.css";

import { type PropsWithChildren } from "react";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LicenseInfo } from "@mui/x-license";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { APP_CONFIG } from "@ll-web/config/app.config";
import { useAmplitude } from "@ll-web/core/analytics/useAmplitude";
import { useKlaviyo } from "@ll-web/core/analytics/useKlaviyo";
import { useSmartlook } from "@ll-web/core/analytics/useSmartlook";
import { queryClient } from "@ll-web/core/api/reactQuerySetup";
import { ActiveAudioProvider } from "@ll-web/core/audio/ActiveAudioProvider";
import { FeatureFlagProvider } from "@ll-web/core/featureFlags/FeatureFlagProvider";
import { router } from "@ll-web/core/router/router";
import { GlobalThemeProvider } from "@ll-web/core/theme/GlobalThemeProvider";
import { GlobalErrorBoundary } from "@ll-web/features/statusPages/components/GlobalErrorBoundary";

import { useSentrySyncContext } from "./core/hooks/useSentry";

LicenseInfo.setLicenseKey(APP_CONFIG.REACT_APP_MUI_LICENSE_KEY);

function App() {
  return (
    <GlobalErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <FeatureFlagProvider>
          <ExternalToolsProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
              <GlobalThemeProvider>
                <ActiveAudioProvider>
                  <RouterProvider router={router} />
                </ActiveAudioProvider>
                <ToastContainer />
              </GlobalThemeProvider>
            </LocalizationProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </ExternalToolsProvider>
        </FeatureFlagProvider>
      </QueryClientProvider>
    </GlobalErrorBoundary>
  );
}

const ExternalToolsProvider = ({ children }: PropsWithChildren) => {
  useSentrySyncContext();
  useAmplitude();
  useSmartlook();
  useKlaviyo();

  return children;
};

export default App;
